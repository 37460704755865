/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"

export function StyledHtmlContent({ dangerouslySetInnerHTML }) {
  const { theme: t } = useThemeUI()
  return (
    <div
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      css={`
        font-family: "stratos", "Arial", "Helvetica", sans-serif;
        font-size: 1.1em;
        line-height: 1.5;
        a,
        a:visited {
          color: ${t.colors.purple};
        }

        p {
          text-align: justify;
          max-width: 60rem;
          a,
          a:visited {
            color: ${t.colors.purple};
          }
        }

        ul,
        ol {
          max-width: 50em;
          display: flex;
          flex-direction: column;
          gap: 0.5em;
        }

        figure {
          width: min(60rem, 100%);
          margin: 2em 0;
          padding: 0;

          figcaption {
            margin: 1em auto;
            text-align: center;
            max-width: 30em;
          }
        }

        details {
          margin-top: 2em;
          margin-bottom: 2em;
        }

        hr {
          margin-top: 4em;
        }

        table {
          margin-top: 2em;
          margin-bottom: 2em;
          border-collapse: collapse;
          td {
            max-width: 100%;
            min-width: 6em;
            padding: 0.3em 0.8em;
            code {
              white-space: nowrap;
            }
          }
          tbody tr:nth-of-type(2n + 1) {
            background-color: ${t.colors.greyLight};
          }
          th {
            padding: 0.5em 0;
          }
        }

        code {
          background: ${t.colors.greyLight};
          padding: 0.1em 0.4em;
          font-family: monospace;
          font-size: 1.1em;
          text-align: left;
        }

        pre {
          overflow: auto;
          background-color: #ecece9;
          border-left: 1em solid ${t.colors.orange};
          margin: 2em 0;

          ::-webkit-scrollbar {
            -webkit-appearance: none;
          }

          ::-webkit-scrollbar:horizontal {
            height: 8px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            border: 2px solid #ffffff;
          }

          ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
          }

          @media (min-width: ${t.breakpoints[1]}) {
            padding: 0 0 0 3em;
          }

          code {
            display: block;
            line-height: 1.75;
            padding: 1.5em;
          }
        }

        blockquote {
          border-left: 0.8em solid ${t.colors.orange};
          padding-left: 1.5em;
        }

        h1 {
          text-transform: uppercase;
          font-size: 2em;
        }

        h2 {
          text-transform: uppercase;
          font-size: 2.2em;
          margin-top: 1.5em;
          line-height: 1;
          max-width: 50rem;
        }

        h3 {
          margin-top: 1.5em;
        }

        img {
          display: block;
          margin: 2em auto;
          width: min(40rem, 100%);
        }

        samp {
          white-space: pre-wrap;
          word-wrap: break-word;
          text-align: justify;
        }

        /* This ensures some additional space is scrolled up to avoid hiding the link
           target (footnote or its reference when jumping back) behind the header bar.*/
        [id|="fnref"],
        [id|="fn"] {
          scroll-margin-top: 15rem;
        }

        /* This implements a quick-highliting of the target. For a second, the
           footnote or its reference when jumping back are highlighted. */
        @keyframes quick-highlight {
          from {
            background: ${t.colors.yellow};
          }
          to {
          }
        }
        [id|="fnref"]:target,
        [id|="fn"]:target {
          animation: quick-highlight 4s;
        }

        .katex-display {
          margin: 2em 0;
          max-width: 60rem;
        }

        // These are the styles used for code highlighting. The classes below
        // are defined by PrismJS, with the highlighting below being based on
        // prism-vs.
        //
        // Some colors were modified to pass most tests from here:
        //
        //     https://wave.webaim.org/
        //
        // using this tools as a helper:
        //
        //     https://contrastchecker.com
        //

        .token.comment,
        .token.prolog,
        .token.doctype,
        .token.cdata {
          color: #763333;
          font-style: italic;
        }

        .token.namespace {
          opacity: 0.7;
        }

        .token.string {
          color: #a31515;
        }

        .token.punctuation,
        .token.operator {
          color: #393a34; /* no highlight */
        }

        .token.url,
        .token.symbol,
        .token.number,
        .token.boolean,
        .token.variable,
        .token.constant,
        .token.inserted {
          color: #037708;
        }

        .token.atrule,
        .token.keyword,
        .token.attr-value,
        .language-autohotkey .token.selector,
        .language-json .token.boolean,
        .language-json .token.number,
        code[class*="language-css"] {
          color: #0000ff;
        }

        .token.function {
          color: #393a34;
        }

        .token.deleted,
        .language-autohotkey .token.tag {
          color: #9a050f;
        }

        .token.selector,
        .language-autohotkey .token.keyword {
          color: #00009f;
        }

        .token.important {
          color: #e90;
        }

        .token.important,
        .token.bold {
          font-weight: bold;
        }

        .token.italic {
          font-style: italic;
        }

        .token.class-name,
        .language-json .token.property {
          color: #2b91af;
        }

        .token.tag,
        .token.selector {
          color: #800000;
        }

        .token.attr-name,
        .token.property,
        .token.regex,
        .token.entity {
          color: #ff0000;
        }

        .token.directive.tag .tag {
          background: #ffff00;
          color: #393a34;
        }
      `}
    />
  )
}
