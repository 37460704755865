/** @jsx jsx */
import { Link, graphql } from "gatsby"
import { jsx, Flex, Text, Box, Paragraph, Heading } from "theme-ui"
import "katex/dist/katex.min.css"

import { StyledHtmlContent } from "../components/styled-html-content"
import cc from "../images/cc.svg"
import ccBy from "../images/cc-by.svg"
import img2 from "../images/img2.svg"
import img6 from "../images/img6.svg"
import aboutAuthorsBackground from "../images/about-authors-background.svg"
import headingImage from "../images/img18.svg"

import {
  PaddedContent,
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
} from "../components/modern/layout"
import { Tags } from "../components/modern/buttons"

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  const prevTitle =
    previous && (previous.frontmatter.shortTitle || previous.frontmatter.title)
  const nextTitle =
    next && (next.frontmatter.shortTitle || next.frontmatter.title)

  const allTags = (post.frontmatter.tags || []).map(tag => ({
    tag,
    link: `/blog/tags/${tag}`,
  }))

  const seoImg = post.frontmatter.seo_image
    ? post.frontmatter.seo_image.childImageSharp.fixed.src
    : null

  const emptyProfilePictureBackgrounds = [img2, img6]
  // find urls to profile pictures that match the full name of the `author` frontmatter (split by commas)
  const allProfiles = [
    ...data.allProfilesModusYaml.edges,
    ...data.allProfilesFormerYaml.edges,
    ...data.allProfilesExternalYaml.edges,
  ]
  const authors =
    // split authors
    post.frontmatter.author
      ?.replace(` and `, ` , `)
      ?.replace(` & `, ` , `)
      ?.split(`,`)
      .map(authorName => authorName.trim())
      // find profile data for each author
      .map(
        authorName =>
          // best case, we have a profile YAML
          allProfiles.find(edge => edge.node.name === authorName)?.node || {
            // otherwise no op
            name: authorName,
          }
      )
      // find corresponding url to profile picture for each author
      .map(author =>
        author.slug
          ? // search profile picture if we have a slug to use
            {
              ...author,
              profilePictureUrl: data.profileImages.edges.find(
                edge => edge.node.name === author.slug
              )?.node.children[0].fixed.src,
            }
          : // otherwise no op
            author
      )

  return (
    <PageWrapper
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
      seoImg={seoImg}
      pathname={post.fields.slug}
    >
      <PageHeading title={post.frontmatter.title} imageSrc={headingImage}>
        <PageHeadingParagraph>
          {post.fields.date} — by{` `}
          {authors.map((author, index) => (
            <span key={index}>
              {author.name}
              {author.company ? ` (${author.company})` : ``}
              {index < authors.length - 1 ? `, ` : ``}
            </span>
          ))}
        </PageHeadingParagraph>
        <Box sx={{ fontSize: `1rem` }}>
          <Tags tags={allTags} />
        </Box>
      </PageHeading>
      <PaddedContent>
        <Box
          sx={{
            maxWidth: `80em`,
            marginX: `auto`,
            marginBottom: `4em`,
          }}
        >
          <StyledHtmlContent
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          />
        </Box>
      </PaddedContent>
      <PaddedContent background="greyLight">
        {authors && authors.length > 0 ? (
          <Box
            sx={{
              marginX: `auto`,
              maxWidth: `80rem`,
              backgroundImage: [`none`, `url(${aboutAuthorsBackground})`],
              backgroundRepeat: `repeat-y`,
              backgroundPosition: `right`,
              backgroundSize: `20em`,
            }}
          >
            <Heading
              sx={{
                textTransform: `uppercase`,
                fontSize: `2em`,
              }}
            >
              {authors.length > 1 ? `About the authors` : `About the author`}
            </Heading>
            <Flex
              sx={{
                marginTop: `2em`,
                gap: `3em`,
                flexDirection: `column`,
                justifyContent: `center`,
              }}
            >
              {authors?.map(author => (
                <Flex
                  key={author.slug}
                  sx={{
                    gap: `1em`,
                  }}
                >
                  <Flex
                    sx={{
                      width: `12em`,
                      flexDirection: `column`,
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: `center`,
                      gap: `1em`,
                    }}
                  >
                    <Box
                      sx={{
                        width: `6em`,
                        height: `6em`,
                        borderRadius: `100em`,
                        backgroundImage: `url(${
                          author.profilePictureUrl ||
                          emptyProfilePictureBackgrounds[
                            Math.floor(
                              Math.random() *
                                emptyProfilePictureBackgrounds.length
                            )
                          ]
                        })`,
                        flexGrow: 0,
                        flexShrink: 0,
                        backgroundSize: `cover`,
                        backgroundPosition: `center`,
                        border: author.profilePictureUrl
                          ? `none`
                          : `solid .2em black`,
                      }}
                    />
                    <Text>
                      {author.name}
                      {author.company ? ` (${author.company})` : ``}
                    </Text>
                  </Flex>
                  <Paragraph
                    sx={{
                      width: `40em`,
                    }}
                  >
                    {author.shortDescription}
                  </Paragraph>
                </Flex>
              ))}
            </Flex>
          </Box>
        ) : null}
      </PaddedContent>
      <PaddedContent background="grey">
        <Flex
          sx={{
            width: `100%`,
            marginX: `auto`,
            flexDirection: `column`,
            gap: `2em`,
            alignItems: `stretch`,
          }}
        >
          <Paragraph
            sx={{
              fontWeight: `bold`,
              marginX: `auto`,
            }}
          >
            If you enjoyed this article, you might be interested in {` `}
            <a href="/careers">joining the Tweag team</a>.
          </Paragraph>
          <Box
            sx={{
              marginX: `auto`,
            }}
          >
            <img
              sx={{ display: `inline-block`, height: `1em`, mr: [`8px`] }}
              className="cc-icon"
              src={cc}
            />
            <img
              sx={{ display: `inline-block`, height: `1em`, mr: [`8px`] }}
              className="cc-icon"
              src={ccBy}
            />
            <Text>
              This article is licensed under a{` `}
              <a href="https://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution 4.0 International
              </a>
              {` `}
              license.
            </Text>
          </Box>
          <Flex
            sx={{
              justifyContent: `space-between`,
              gap: `3em`,
              flexGrow: 1,
              flexWrap: `wrap`,
            }}
          >
            {previous && (
              <Link
                sx={{ color: `black` }}
                to={previous.fields.slug}
                rel="prev"
              >
                <Text>← {prevTitle}</Text>
              </Link>
            )}
            {next && (
              <Link sx={{ color: `black` }} to={next.fields.slug} rel="next">
                <Text>{nextTitle} →</Text>
              </Link>
            )}
          </Flex>
        </Flex>
      </PaddedContent>
    </PageWrapper>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        date(formatString: "D MMMM YYYY")
        slug
      }
      frontmatter {
        title
        shortTitle
        author
        description
        tags
        seo_image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    profileImages: allFile(
      filter: { sourceInstanceName: { eq: "profilePictures" } }
    ) {
      edges {
        node {
          name
          children {
            ... on ImageSharp {
              id
              fixed(
                height: 400
                width: 400
                cropFocus: NORTH
                toFormat: WEBP
                webpQuality: 85
              ) {
                src
              }
            }
          }
        }
      }
    }
    allProfilesModusYaml {
      edges {
        node {
          name
          slug
          shortDescription
        }
      }
    }
    allProfilesFormerYaml {
      edges {
        node {
          name
          slug
          shortDescription
        }
      }
    }
    allProfilesExternalYaml {
      edges {
        node {
          name
          slug
          company
          shortDescription
        }
      }
    }
  }
`
